.course-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(102, 0, 204, 0.08);
}

button:hover {
    opacity: 0.9;
}

input:focus {
    outline: none;
    border-color: #6600CC !important;
    box-shadow: 0 0 0 3px rgba(102, 0, 204, 0.1);
}

.page-wrapper {
    background-color: #f8f9fa;
}

.timeline-item {
    position: relative;
    transition: all 0.3s ease;
}

.timeline-item:hover {
    transform: translateY(-2px);
}

.timeline-arrow {
    display: inline-block;
    transition: all 0.3s ease;
}

/* Add subtle animation on hover */
.timeline-item:hover + .timeline-arrow {
    transform: translateX(2px);
    color: #6600CC;
}